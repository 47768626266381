@import "../../../index.scss";

.buy-header {
  .service-img-box h1 {
    font-size: 60px;
  }
}

.buy-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 40px;
    padding-top: 30px;
    font-family: "Italianno", cursive;
    font-weight: 100;
  }
  .buy-text,
  .buy-text-2 {
    display: flex;
    flex-direction: row;
    padding: 50px 10%;
    justify-content: space-around;
    article {
      display: flex;
      flex-direction: column;
      width: 40%;
      text-align: justify;
      line-height: 1.5;
      h3 {
        font-size: 35px;
        text-align: left;
        margin-bottom: 5px;
        font-weight: 100;
      }
    }
    img {
      width: 30%;
      height: auto;
      object-fit: cover;
    }
  }
  h1 {
    font-size: 40px;
    font-family: "Italianno", cursive;
    font-weight: 100;
  }
}

.buy-text-box .sell-bottom__text {
  padding: 50px 15%;
  font-size: 35px;
  text-align: center;
}
.rent-foot {
  padding: 60px 20% 30px;
  text-align: center;
  font-size: 30px;
  height: 200px;
  background-image: url("../../../images//pattern-heather.png");
  background-repeat: repeat;
  background-attachment: fixed;
  color: white;
  width: 60%;
}

@media (max-width: 700px) {
  .buy-header .service-img-box h1 {
    font-size: 30px;
  }
  .buy-text-box {
    h1{
      font-size: 28px;
    }
    h2 {
      text-align: center;
    }
    .buy-text {
      flex-direction: column;
    }
    .buy-text-2 {
      flex-direction: column-reverse;
    }
    .buy-text article,
    .buy-text-2 article {
      width: 90%;
      padding: 5px 5%;
      h3{
        text-align: center;
      }
    }
    .buy-text img,
    .buy-text-2 img{
      width: 100%;
    }
    .sell-bottom__text{
      font-size: 28px;
    }
  }
.rent-foot{
  height: auto;
}
}
