@import "../../index.scss";

.contact-cont {
  width: 100%;
  height: 100%;
  background-color: $main-green;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 200px;
  align-items: center;
  .contact-title {
    height: 300px;
    width: 100%;
    background-image: url("../../images/pattern-dark-green.png");
    background-repeat: repeat;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-family: "Josefin Sans";
      font-size: 40px;
      text-transform: uppercase;
      color: white;
      margin-bottom: 0px;
    }
    p {
      font-size: 26px;
      color: white;
      font-weight: 100;
    }
  }
  .contact-box {
    width: 60%;
    height: 500px;
    background-color: $light-gray;
    display: flex;
    flex-direction: row;
 
    button {
      text-align: center;
      padding: 13px;
      font-family: "Josefin Sans", sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      width: 170px;
      color: white;
      cursor: pointer;
      transition: 0.5s ease-in-out;
      background-color: $main-green;
      border: none;
&:disabled{
  background-color: $light-green;
}
      &:hover {
        color: $main-green;
        border: none;
        background-color: $light-gray;
       
      }
    }
 
    form {
      width: 70%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      h1 {
        grid-area: 1 / 1 / 2 / 3;
        font-family: "Josefin Sans";
        text-transform: uppercase;
        font-weight: 800;
        font-size: 26px;
        padding-top: 10px;
        color: $main-green;
      }
      .name,
      .lastname,
      .email,
      .phone,
      .textbox {
        background-color: $light-gray;
        border: 1px solid $main-green;
        padding: 4px 8px;
        font-size: 12px;
        font-family: "Josefin Sans";
      }
      .name {
        grid-area: 2 / 1 / 3 / 2;
        height: 40px;
        margin: 0px 10px;
      }
      .lastname {
        grid-area: 2 / 2 / 3 / 3;
        height: 40px;
        margin: 0px 10px;
      }
      .email {
        grid-area: 3 / 1 / 4 / 2;
        height: 40px;
        margin: 0px 10px;
      }
      .phone {
        grid-area: 3 / 2 / 4 / 3;
        height: 40px;
        margin: 0px 10px;



      }
      .textbox {
        grid-area: 4 / 1 / 6 / 3;
        margin: 10px;
        height: 200px;
        padding: 20px 10px;
        resize: none;
      }
      .submit {
        grid-area: 6 / 1 / 7 / 3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 30px;

        margin: 10px;
      }
      aside {
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
      }
    }
    aside {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 30%;
      justify-content: center;
      height: 100%;
      p {
        margin: 5px;
        font-weight: 600;
        color: $main-green;
        transform: translateY(-100px);
      }
    }
    
  }
.contact-info_text{
  width: 60%;
  color: white;
  font-size: 12px;
  margin-bottom: 80px;
}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 750px ) {
  .contact-cont .contact-box{
    width: 100%;
    height: auto;
    flex-direction: column;
    form{
      width: 100%;
      display: flex;
      flex-direction: column;
      .name,
      .lastname,
      .email,
      .phone,
      .textbox{
        margin: 10px
      }
    };
    aside{
      width: 100%;
      padding: 20px 10px;
      p{
        transform: translateY(0px);
      }
    };
  }
  .contact-cont .contact-info_text {
    margin: 30px 0px;
  }
  
}
