@import "../../index.scss";

.navbar {
  left: 0;
  padding: 30px 75px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  z-index: 50;

  img {
    cursor: pointer;
    left: 50%;
    position: absolute;
    top: 20px;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    width: 213px;
  }
  .media-box {
    position: absolute;
    right: 95px;
    top: 23px;
    svg {
      transition: all 0.3s ease-in-out;
      font-size: 22px;
      padding: 0px 6px;
      color: white;
      cursor: pointer;
      &:hover {
        color: $light-green;
      }
    }
  }
  h3 {
    cursor: pointer;
    position: absolute;
    right: 100px;
    top: 42px;
    font-size: 15px;
    background-color: white;
    transition: all 0.3s ease-in-out;
    padding: 5px 25px 20px;
    border-radius: 0px 0px 35px 35px;
    font-family: "Josefin Sans", sans-serif;
    &:hover {
      border-radius: 35px 35px 35px 0px;
      padding: 15px 25px 10px;
      background-color: $main-green;
      color: white;
    }
  }

  .lang-select{
   transform: translateY(-2px);
   border: 0px;
   &:hover{
    color: $main-green;
    cursor: pointer;
   }
  }
}

nav .menu-icon {
  cursor: pointer;
  padding: 28px 20px;
  height: 25px;
  left: 52px;
  overflow: hidden;
  position: absolute;
  text-indent: -12345px;
  top: 47px;
  width: 35px;
  &:hover {
    .nav-icon {
      height: 2.5px;
      background-color: $light-pink;
    }
    .nav-icon:before {
      transform: rotateZ(45deg) scaleX(0.8) translate(-20px, 8px);
      margin-left: 11px;
      background-color: $light-pink;

      width: 24px;
    }
    .nav-icon:after {
      transform: rotateZ(-45deg) scaleX(0.8) translate(-20px, -8px);
      margin-left: 11px;
      background-color: $light-pink;

      width: 24px;
    }
  }
}

nav .menu-icon .nav-icon {
  background-color: white;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 30px;
  transition: 0.5s ease-in-out;
}
nav .menu-icon .nav-icon:before,
nav .menu-icon .nav-icon:after {
  background: #f8f8f8;
  content: "";
  display: block;
  height: 70%;
  position: absolute;
  transition: all ease-out 0.2s;
  width: 100%;
  height: 2px;
  transition: 0.5s ease-in-out;
}
nav .menu-icon .nav-icon:before {
  top: 12px;
  background-color: white;
}
nav .menu-icon .nav-icon:after {
  top: -12px;
  background-color: white;
}

.navbar-transparent {
  background-color: rgba($color: #000000, $alpha: 0);
  height: 170px;
}
.navbar-white {
  background-color: white;
  height: 50px;
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 30%);
  .menu-icon .nav-icon,
  .menu-icon .nav-icon:before,
  .menu-icon .nav-icon:after {
    background-color: $main-dark;
  }
  .menu-icon .nav-icon {
    transform: translateY(-16px);
  }
  img {
    width: 113px;
  }
  .media-box {
    svg {
      color: $main-dark;
      font-size: 15px;
      &:hover {
        color: $main-green;
      }
    }
  }
  h3 {
    color: white;
    background-color: $main-dark;
    font-size: 12px;
    padding: 5px 18px 13px;
    &:hover {
      border-radius: 35px 35px 35px 0px;
      padding: 11px 18px 7px;
      background-color: $main-dark;
    }
  }
}

.menu-hidden {
  transition: all 0.5s ease-in-out;
  left: -100%;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 60;
  background-color: $light-gray;
}
.side-menu {
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.5s ease-in-out;
  left: 0;
  z-index: 60;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  backface-visibility: hidden;
  min-height: 100vh;
  .stay-tuned__box {
    padding: 50px;
  }
  .side-icon {
    cursor: pointer;
    padding: 28px 20px;
    height: 25px;
    left: 52px;
    overflow: hidden;
    position: absolute;
    text-indent: -12345px;
    top: 47px;
    width: 35px;
    &:hover {
      .side-nav-icon,
      .side-nav-icon:after {
        background-color: $light-pink;
      }
    }
  }
  .side-icon .side-nav-icon {
    background-color: $main-dark;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 30px;
    transform: rotate(45deg);
    opacity: 1;
    transition: 0.5s ease-in-out;
  }
  .side-icon .side-nav-icon:before,
  .side-icon .side-nav-icon:after {
    background: $main-dark;
    content: "";
    display: block;
    height: 70%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
    height: 2px;
    transition: 0.5s ease-in-out;
  }
  .side-icon .side-nav-icon:before {
    top: 12px;
    background-color: $main-dark;
    opacity: 0;
  }
  .side-icon .side-nav-icon:after {
    top: 0px;
    background-color: $main-dark;
    transform: rotate(90deg);
  }

  .service-hidden {
    height: 0px;
    display: none;
    color: white;
    transition: all 0.4 ease;
  }
  .side-service {
    height: 220px;
    transition: all 0.4 ease;
    color: $main-dark;
    display: block;

    li,
    a {
      font-size: 29px;
      line-height: 1.9;
    }
  }
  .side-tricity{
    height: 170px;
    transition: all 0.4 ease;
    color: $main-dark;
    display: block;

    li,
    a {
      font-size: 29px;
      line-height: 1.9;
    }
  }
}

.side-logo {
  width: 100px;
  position: absolute;
  align-self: center;
  padding-top: 20px;
}
.side-box {
  margin: 8% 10% 1%;
  width: 80%;
  height: auto;
  background-image: url("../../images/pattern-white.png");
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.side-texts {
  grid-area: 1 / 1 / 2 / 2;

  margin-left: 100px;

  li,
  a {
    text-decoration: none;
    color: $main-dark;
    list-style-type: none;
    text-transform: uppercase;
    font-size: 36px;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 800;
    line-height: 2;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    &:hover {
      color: $main-green;
    }
  }
}
.side-offer-box {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: white;
  margin: 5% 15%;
  text-align: center;
  align-items: center;
  h3 {
    font-size: 40px;
    margin: 10px;
    font-weight: 300;
  }
  h4 {
    font-size: 34px;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    color: $main-green;
    margin: 12px;
    line-height: 1.2;
  }
  img {
    width: 90%;
    height: 200px;
    object-fit: cover;
  }
  button {
    background-color: $light-green;
    border: 0px;
    padding: 15px;
    width: 300px;
    text-align: left;
    margin-bottom: 15px;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    border: none;
    &:after {
      content: url("../../images/assets/arrow-right.svg");
      position: absolute;
      transition: all 0.5s ease-in-out;

      transform: translateX(150px) translateY(-2px) scaleX(0.9);
    }
    &:hover {
      color: white;
      border: none;
      background-color: #56726b;
      &::after {
        transform: translateX(145px) translateY(-2px) scaleX(1.3);
        transition: all 0.5s ease-in-out;
        filter: invert(0%) sepia(0%) saturate(1112%) hue-rotate(0)
          brightness(561%) contrast(198%);
      }
    }
  }
}


@media (max-width: 700px) {
  .menu-hidden{
    left: -1000px;
  }
  .navbar{
    padding: 30px 10px;
    img{
      width: 123px;
    }
    .media-box svg {
      font-size: 12px;
      padding: 4px;
    }
    h3{
      right: 15px;
      padding: 5px 10px 12px;
      font-size: 14px;
    }
    .media-box{
      right: 15px;
    }
    .menu-icon{
      left: 18px;
    }
  }
  .navbar-white img{
    width: 70px;
    top: 30px;
  }
  .side-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 100px;
    margin: 5px;
    .side-texts{
      margin-left: 0px;
      a, li{
        font-size: 22px;
      }
    }
  }
  .side-offer-box{
    margin: 5px;
  }
  .side-menu .side-service{
    height: auto;
  }
  .side-menu .side-tricity{
    height: auto;
  }
}