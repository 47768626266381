@import "../../index.scss";

footer {
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 300px;
}

.footer-logo__holder {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  img {
    height: 100px;
    width: 150px;
  }
}
.footer-links  {
  grid-area: 1 / 2 / 2 / 3;
  height: 60%;
  padding-top: 10%;
  font-family: "Josefin Sans", sans-serif;
  list-style-type: none;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1;
  li, a {
    margin: 4px;
    cursor: pointer;
    text-decoration: none;
    color: $main-dark;
    &:hover{
      color: $main-green;
      transition: 0.3s ease-in-out;
    }
    
  }
}
.footer-address {
  grid-area: 1 / 3 / 2 / 4;
  height: 60%;
  padding-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 5px;
    font-size: 20px;
  }
  h6 {
    margin: 15px 5px;
    font-size: 20px;
    font-weight: 400;
    color: $main-dark;
  }
  svg {
    transition: all 0.3s ease-in-out;
    font-size: 22px;
    padding: 0px 6px;
    color: $main-dark;
    cursor: pointer;
    &:hover {
      color: $main-green;
    }
  }
}
@media (max-width: 700px) {
  footer {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  
    .footer-links {
      grid-area: 2 / 1 / 3 / 2;
      text-align: center;
      padding: 5px;
    }
    .footer-address {
      grid-area: 3 / 1 / 4 / 2;
      text-align: center;
      padding: 5px 5px 50px;
    }
  }
}
.rodo{
  padding-top: 150px;
  background-color: $light-gray;
  background: linear-gradient(180deg, rgba(102,128,121,1) 0%, rgba(102,128,121,0) 18%);

  text-align: center;
li{
  text-decoration: none;
  list-style-type: none;
}
}
.nota{
  padding-top: 200px;
  background-color: $light-green;
  height: 100vh;
  text-align: center;
  margin: auto;
  color: $main-dark;
  h1{
    font-family: "Josefin Sans";
  }
}