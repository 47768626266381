@import "../../index.scss";

.service-header__container {
  background: url("../../images/pattern-white.png");
  background-attachment: fixed;
  height: 98vh;
  width: 92%;
  padding: 0px 4% 50px;
  .service-img-box {
    width: 100%;
    height: 100%;
    display: flex;
    h1 {
      position: absolute;
      top: 40vh;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      color: white;
      z-index: 1;

      font-size: 40px;
      font-family: "Josefin Sans", sans-serif;
      text-align: center;
    }
    h3 {
      position: absolute;
      top: 60vh;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      color: white;
      z-index: 1;
      font-size: 50px;
      font-family: "Italianno", cursive;
      font-weight: 100;
      padding: 0px 100px 20px;
      border-bottom: 1px solid;
    }
    img {
      height: 95vh;
      width: 100%;
      object-fit: cover;
      filter: brightness(0.7);
    }
  }
}
.service-about {
  display: flex;
  flex-direction: column;
  color: white;
  background-image: url("../../images/pattern-heather.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  min-height: 400px;
  .serv-title {
    text-align: center;
    background-color: $light-pink;
  }
  .serv-title h3 {
    width: 50%;
    color: white;
    font-size: 18px;
    padding: 50px 25% 0px;
  }
  .serv-title h4 {
    width: 50%;
    color: white;
    font-family: "Italianno", cursive;
    font-weight: 100;
    font-size: 26px;
    padding: 0px 25% 50px;
  }
  img {
    width: 60%;
    object-fit: cover;
    height: 450px;
    padding-left: 50px;
    padding-right: 50px;
    object-position: top;
  }
  .service-text__container {
    color: white;
    flex-wrap: wrap;
    padding-bottom: 100px;
    h4 {
      color: white;
      font-size: 27px;
    }
    h3 {
      color: white;
    }
    p {
      font-size: 17px;
      text-align: justify;
    }
  }

  .serv-text-box {
    padding-top: 120px;

    font-size: 22px;
    background-color: $main-green;
    .bg-holder {
      background-image: url("../../images/pattern-dark-green.png");
      background-repeat: repeat;
      background-position: fixed;
      display: flex;
      flex-direction: row;
      text-align: justify;
      justify-content: space-around;
      padding-bottom: 60px;

      .img-holder {
        display: flex;
        flex-direction: column;
        width: 60%;
        h3 {
          color: white;
          text-align: center;
          font-family: "Italianno", cursive;
          font-weight: 100;
          font-size: 35px;
        }
        img {
          width: 100%;
        }
      }
      .service-text__container {
        p {
          margin-top: 0px;
        }

        h3 {
          font-family: Cormorant Garamond, serif;
          border-bottom: 1px solid white;
          border-top: 1px solid white;
          font-size: 22px;
        }
        h4 {
          font-size: 17px;
          &::first-letter {
            font-size: 30px;
          }
        }
      }
    }
  }
}

.service-link_box {
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  background-color: white;
  .bg-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("../../images/pattern-white.png");
    background-repeat: repeat;
    background-position: fixed;
    h1 {
      text-align: center;
      font-size: 45px;
      text-transform: uppercase;
      font-family: "Josefin Sans", sans-serif;
      color: $main-green;
      margin-bottom: 10px;
    }
    .serv-windows {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 80%;
      padding: 20px 10%;
      .wind-box {
        width: 30%;
        min-width: 400px;
        height: 400px;
        display: flex;
        flex-direction: column;
        margin: 20px;
        justify-content: center;
        align-items: center;
        background-color: white;
        img {
          width: 90%;
          height: 200px;
          object-fit: cover;
          margin: 15px;
          color: $main-dark;
        }
        h3 {
          text-transform: uppercase;
          font-family: "Josefin Sans", sans-serif;
          font-size: 25px;
          text-align: center;
        }
        button {
          text-align: left;
          padding: 16px;
          font-family: "Josefin Sans", sans-serif;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          width: 250px;
          background-color: $main-green;
          color: white;
          cursor: pointer;
          margin-top: 20px;
          transition: 0.5s ease-in-out;
          border: none;
          &:after {
            content: url("../../images/assets/arrow-right.svg");
            position: absolute;
            filter: invert(0%) sepia(0%) saturate(1112%) hue-rotate(0)
              brightness(561%) contrast(198%);
            transition: all 0.5s ease-in-out;
            transform: translateX(90px) translateY(-2px) scaleX(0.9);
          }
          &:hover {
            color: $main-green;
            border: none;
            background-color: $light-green;
            &::after {
              transform: translateX(85px) translateY(-2px) scaleX(1.3);
              transition: all 0.5s ease-in-out;
              filter: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {

  .service-header__container{
    width: 100vw;
    padding: 0px 0px 50px;
  }
  .service-header__container .service-img-box {
    h1 {
      font-size: 26px;
    }
    h3 {
      font-size: 26px;
    }
  }
  .service-about {
    img {
      width: 80%;
      align-self: center;
      padding: 5px;
    }
  }

  .service-about .serv-text-box .bg-holder {
    flex-direction: column;
    .img-holder {
      width: 90%;
      align-self: center;
    }
  }
  .service-text__container {
    width: 90%;
    align-self: center;
  }

  .service-link_box .bg-box .serv-windows {
    flex-direction: column;
    .wind-box {
      min-width: 0px;
      width: auto;
      button {
        width: 200px;
        &::after {
          transform: translateX(40px) translateY(-2px) scaleX(0.9);
        }
        &:hover {
          &::after {
            transform: translateX(35px) translateY(-2px) scaleX(1.3);
          }
        }
      }
    }
  }
}
