@import "../../../index.scss";

.offer-slider-box {
  width: 100%;
  padding: 15px 0%;
  background: url("../../../images/pattern-white.png");
  background-attachment: fixed;
  padding-bottom: 90px;
  .slick-arrow {
    background-size: 46px 46px;
    bottom: 65px;
    height: 50px;
    transition: width 0.3s ease-in-out;
    width: 30px;
    will-change: width;
    cursor: pointer;
    height: 30px;
    position: absolute;
  }
  .slick-prev {
    transform: translateX(-313px) translateY(260px);
    background-position: 0;
    left: 50%;
    right: auto;
    background-image: url("../../../images/assets/carousel-prev-dark-grey.svg");
    z-index: 15;
    &:hover {
      width: 46px;
      transform: translateX(-313px) translateY(260px);
      background-position: 0;
      left: 50%;
      right: auto;
      background-image: url("../../../images/assets/carousel-prev-dark-grey.svg");
      z-index: 15;
    }
    &::before {
      opacity: 0 !important;
    }
  }
  .slick-next {
    background-position: 100%;
    left: auto;
    right: 50%;
    background-image: url("../../../images/assets/carousel-next-dark-grey.svg");
    transform: translateX(313px) translateY(260px);
    z-index: 15;
    &:hover {
      width: 46px;
      background-position: 100%;
      left: auto;
      right: 50%;
      background-image: url("../../../images/assets/carousel-next-dark-grey.svg");
      transform: translateX(313px) translateY(260px);
      z-index: 15;
    }
    &::before {
      opacity: 0 !important;
    }
  }
}
.slick-dots li {
  display: none;
}
.slider-button {
  opacity: 0;
  display: flex;
  justify-content: center;
  transition: 0.5s ease-in-out;

  button {
    text-align: left;
    padding: 16px;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    width: 250px;
    background-color: $main-green;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.5s ease-in-out;
    border: none;
    &:after {
      content: url("../../../images/assets/arrow-right.svg");
      position: absolute;
      filter: invert(0%) sepia(0%) saturate(1112%) hue-rotate(0)
        brightness(561%) contrast(198%);
      transition: all 0.5s ease-in-out;
      right: 120px;
      transform: translateY(-2px) scaleX(0.9);
    }
    &:hover {
      color: $main-green;
      border: none;
      background-color: $light-green;
      &::after {
        transform:  translateY(-2px) scaleX(1.3);
        transition: all 0.5s ease-in-out;
        right: 125px;
        filter: none;
      }
    }
  }
}
.slider-offer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: baseline;
  opacity: 0.2;
  transform: scale(0.9);
  transform-origin: center top;
  transition: all 0.5s ease-in-out;
  width: 30vw;
  align-items: center;
  img {
    width: 80%;
    height: 230px;
    padding-top: 30px;
    object-fit: cover;
  }
  h1 {
    font-family: "Josefin Sans", sans-serif;
    color: $main-green;
    font-size: 21px;
    text-transform: uppercase;
  }
  h3 {
    font-size: 16px;
    padding: 0px 20%;
    margin: 2px;
  }
  p {
    font-size: 16px;
    margin: 7px;
  }
  .slider-details__box {
    display: flex;
    width: 80%;
    justify-content: center;
    p {
      font-family: "Josefin Sans", sans-serif;
      margin: 2px 10px;
    }
  }
}
.offer-slider-box .slick-slider {
  width: 100%;
  background-color: transparent;
  padding-top: 100px;
  .slick-track {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .slick-slide {
    float: none;
    display: inline-block;
    width: 420px;
    vertical-align: middle;
    padding: 10px 0px;
    transition: all 0.3s ease;
    height: auto;
    text-align: center;
    &.slick-active {
      width: 420px;
      height: 470px;
    }
    &.slick-current,
    &.slick-center .slider-offer,
    &.slick-center .slider-button {
      background-color: #fff;
      opacity: 1;
      transform: scale(1);
    }
  }
}

.offer-slider-box .slick-slide {
  opacity: 0;
}
.offer-slider-box .slick-slide.slick-center,
.offer-slider-box .slick-slide.slick-active {
  opacity: 1;
  display: flex;
  justify-content: center;
}
@media (max-width: 700px) {
  .offer-slider-box {
    .slick-arrow {
      background: no-repeat;
      background-size: 30px 30px;
      bottom: -40px;
      cursor: pointer;
      height: 30px;
      position: absolute;
      text-indent: -12345px;
      width: 30px;
      z-index: 20;
      transform: none;

    }
      .slick-next {
        left: calc(50% + 10px);
      }
      .slick-prev {
        right: calc(50% + 10px);
      }
   
  }

  .slider-offer{
    background-color: white;
    opacity: 1;
    width: auto;
    h1{
      padding: 0px 30px;
    }
  }
  .slider-button{
    opacity: 1;
  }
}
