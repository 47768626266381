@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;1,400;1,500&family=Josefin+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
$smoke-white: #F9F9F9;
$light-green: #D9E3DF;
$light-gray: #F4F5F4;
$main-green: #668079;
$light-pink: #847179;
$main-dark: #3B3D42;

.h1, .h2, .h3, .h4, .h5, .h6, body, h1, h2, h3, h4, h5, h6 {
    color: #3b3d42;
    font-family: Cormorant Garamond,serif;
}