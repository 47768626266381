@import "../../index.scss";

.main-container {
  width: 100%;
  height: calc(100vh);
  background-color: $main-green;
}
.video-box {

  padding: 0px 2vw;
  display: flex;
  transform: translateY(-12%);
  // &::after {
  //   width: 100%;
  //   height: 100px;
  //   z-index: 20;
  //   background-color: $main-green;
  //   content: "";
  //   position: absolute;
  //   top: calc(96vh - 50px);
  //   left: 0px;
  // }
}
.main-video {
  overflow: hidden;
  width: 100%;
  left: 0px;
  top: 0;
}
.video-box {
  position: relative;
  overflow: hidden;
  width: 96vw;
  height: 96vh;

   &::after {
    width: 96vw;
    height: 96vh;
    z-index: 20;
    background-color: rgba($color: #000000, $alpha: 0.3);
    content: "";
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video-box iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 96vw;
  height: 96vh;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .video-box iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-box iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}
.header-about {
  background-color: $main-green;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-bottom: 50px;
  img {
    width: 60%;
    padding-left: 50px;
  }
}
.header-about__text {
  display: flex;
  align-items: center;
}
.header-about__title {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  width: 94%;
  height: 250px;
  margin: 0px 3%;
  justify-content: space-evenly;
  background-image: url("../../images/pattern-dark-green.png");
  min-height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;

  h3 {
    color: white;
    font-family: "Italianno", cursive;
    font-size: 45px;
    text-align: center;
    font-weight: 100;
  }
  p {
    font-size: 28px;
    padding-top: 50px;
  }
}

.about-text__container {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 0px 5%;
  color: white;
  h3 {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
  }
  h4 {
    font-size: 30px;
    color: white;
    margin: 0px;
    font-weight: 400;
  }
  p {
    font-size: 19px;
  }

  button {
    text-align: left;
    padding: 16px;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    width: 350px;
    color: $main-green;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.5s ease-in-out;
    border: none;

    &:after {
      content: url("../../images/assets/arrow-right.svg");
      position: absolute;
      transition: all 0.5s ease-in-out;

      transform: translateX(200px) translateY(-2px) scaleX(0.9);
    }
    &:hover {
      color: white;
      border: none;
      background-color: #56726b;
      &::after {
        transform: translateX(195px) translateY(-2px) scaleX(1.3);
        transition: all 0.5s ease-in-out;
        filter: invert(0%) sepia(0%) saturate(1112%) hue-rotate(0)
          brightness(561%) contrast(198%);
      }
    }
  }
}

.main-service {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  img {
    width: 60%;
  }
}
.header-service__text {
  display: flex;
  justify-content: space-around;
}

.header-service__title {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  width: 94%;
  height: 400px;
  margin: 0px 3%;
  justify-content: space-evenly;
  background-image: url("../../images/pattern-white.png");
  min-height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;

  h3 {
    color: $main-green;
    font-family: "Italianno", cursive;
    font-size: 45px;
    text-align: center;
    font-weight: 100;
  }
  p {
    font-size: 23px;
    padding-top: 50px;
    color: $main-dark;
    width: 40%;
    text-align: justify;
  }
}

.service-text__container {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 0px 5%;
  color: $main-dark;
  h3 {
    font-family: "Josefin Sans", sans-serif;
    color: $main-dark;
    border-bottom: 1px solid $main-dark;
    padding-bottom: 10px;
  }
  h4 {
    font-size: 22px;
    color: $main-dark;
    margin: 0px;
    font-weight: 400;
  }
  p {
    font-size: 19px;
    font-weight: 500;
  }
  button {
    text-align: left;
    padding: 16px;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    width: 350px;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.5s ease-in-out;
    border: none;
    text-decoration: none;
    color: $main-green;
    transition: 0.5s ease-in-out;
    &:after {
      content: url("../../images/assets/arrow-right.svg");
      position: absolute;
      transition: all 0.5s ease-in-out;
      transform: translateX(200px) translateY(-2px) scaleX(0.9);
    }
    &:hover {
      color: white;
      border: none;
      background-color: #56726b;
      &::after {
        transform: translateX(195px) translateY(-2px) scaleX(1.3);
        transition: all 0.5s ease-in-out;
        filter: invert(0%) sepia(0%) saturate(1112%) hue-rotate(0)
          brightness(561%) contrast(198%);
      }
    }
  }
}

.main-tricity {
  background-color: $light-pink;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-bottom: 50px;
  img {
    width: 60%;
    padding-left: 50px;
  }
}
.header-tricity__text {
  display: flex;
  align-items: center;
}
.header-tricity__title {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  width: 94%;
  height: 250px;
  margin: 0px 3%;
  justify-content: space-evenly;
  background-image: url("../../images/pattern-heather.png");
  min-height: 250px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;

  h3 {
    color: white;
    font-family: "Italianno", cursive;
    font-weight: 100;
    font-size: 45px;

    text-align: center;
  }
  p {
    font-size: 28px;
  }
}

.tricity-text__container {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 0px 5%;
  color: white;
  h3 {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
  }
  h4 {
    font-size: 30px;
    color: white;
    margin: 0px;
    font-weight: 400;
  }
  p {
    font-size: 19px;
  }
  button {
    text-align: left;
    padding: 16px;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    width: 350px;
    color: $main-dark;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.5s ease-in-out;
    border: none;
    &:after {
      content: url("../../images/assets/arrow-right.svg");
      position: absolute;
      transition: all 0.5s ease-in-out;

      transform: translateX(200px) translateY(-2px) scaleX(0.9);
    }
    &:hover {
      color: $main-dark;
      border: none;
      background-color: #c1b8bc;
      &::after {
        transform: translateX(195px) translateY(-2px) scaleX(1.3);
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
@media (max-width: 700px) {
  .video-box::after {
    width: 100vw;
    height: 96vh;
  }
  .header-about__title {
    flex-direction: column;
    p {
      font-size: 20px;
      padding: 0px 30px;
    }
  }
  .header-about__text {
    flex-direction: column;
    img {
      width: 90%;
      padding: 5px;
    }
    .about-text__container {
      width: 90%;
      padding: 40px 5%;
    }
  }
  .header-service__title {
    flex-direction: column-reverse;
    height: auto;
    p {
      width: 100%;
      padding-top: 0px;
    }
  }

  .about-text__container button,
  .service-text__container button,
  .tricity-text__container button{
    width: 200px;
    &::after{
      transform: translateX(50px) translateY(-2px) scaleX(0.9);

    }&:hover{
      &::after{
        transform: translateX(45px) translateY(-2px) scaleX(1.3);
      }
    }
  }
  .header-service__text {
    flex-direction: column-reverse;
    img {
      width: 90%;
      padding: 5px 5%;
    }
    .service-text__container {
      width: 90%;
      padding: 5px 5%;
    }
    
  }
  .header-tricity__title {
    flex-direction: column;
    justify-content: center;
    height: 250px;
    h3 {
      margin: 5px;
    }
    p {
      padding-top: 0px;
      margin: 5px;
    }
  }
  .header-tricity__text {
    flex-direction: column;
  }
  .main-tricity img{
    width: 90%;
    padding: 5px;
  }
  .tricity-text__container{
    width: 90%;
    padding: 5px 5%;
  }
}
