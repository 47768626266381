@import "../../index.scss";

.about-head {
  height: 90vh;
  min-height: 700px;
  width: 94vw;
  margin: 0px 3%;
  background: url("../../images/nat8.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &::before {
    content: "";
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    display: block;
    min-height: 700px;
    height: 90vh;
    width: 94vw;
    position: absolute;
  }
  h1 {
    color: white;
    padding-top: 140px;
    font-size: 50px;
    z-index: 1;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  p {
    color: white;
    z-index: 1;
    font-family: "Italianno", cursive;
    font-size: 40px;
    margin: 0px;
  }
}

.about-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-size: 40px;
    text-align: center;
    padding: 40px 0px;
    margin-top: 60px;
    background-image: url("../../images/pattern-white.png");
    background-repeat: repeat;
    background-attachment: fixed;
    width: 100%;
    height: 140px;
    font-family: "Italianno", cursive;
    font-weight: 100;
  }
  .about-text {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;

    padding-bottom: 100px;
    article {
      width: 30%;
      text-align: justify;
      font-size: 24px;
      &::first-letter {
        font-size: 40px;
      }
    }
    img {
      width: 20%;
      min-width: 130px;
      height: 400px;
      object-fit: cover;
      object-position: top;
    }
  }
}
.about-bot {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h4 {
    font-size: 30px;
    margin: 5px;
    font-weight: 100;
  }
  h2 {
    font-family: "Italianno", cursive;
    font-weight: 100;
    font-size: 60px;
  }
}
@media (max-width: 700px) {
  .about-head{
    width: 100vw;
    margin: 0px;
    &::before{
      width: 100vw;
    }
  }
  .about-head h1 {
    text-align: center;
  }
  .about-top h3 {
    font-size: 26px;
    height: auto;
  }
  .about-top .about-text {
    flex-direction: column;
    article {
      width: 90%;
      padding: 5px 5%;
      margin: 0px;
      font-size: 18px;
    }
    img {
      width: 70%;
      padding: 5px 15%;
    }
  }
  .about-top .about-text:nth-of-type(2) {
    flex-direction: column-reverse;
  }

  .about-bot{
    h4{
        font-size: 22px;
    }
    h2{
        font-size: 26px;
    }
  }
}
